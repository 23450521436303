<template>
  <v-app>
    <authentication-service-provider
      ref="authenticationServiceProvider"
      v-slot="{ login, loginSocial, goToSignUp, loaders, forms, errorMessage }"
      @login-success="onLoginSuccess"
      @login-social-success="onLoginSocialSuccess"
    >
      <div class="wrapper">
        <locale-changer class="pb-3" />

        <div class="form-wrapper">
          <!-- Title -->
          <h1>
            {{ $t('auth.logInPayment.title') }}
          </h1>
          <!-- /Title -->

          <!-- Alert -->
          <v-alert
            v-show="errorMessage"
            border="left"
            color="red"
            type="error"
          >
            {{ errorMessage }}
          </v-alert>
          <!-- /Alert -->

          <!-- Login with Google -->
          <v-btn
            class="button-google"
            block
            x-large
            elevation="0"
            outlined
            :disabled="loaders.socialLogin || loaders.login"
            :loading="loaders.socialLogin"
            color="secondary"
            @click="loginSocial('google')"
          >
            <img src="@/assets/img/logos/google.png" alt="google" class="mr-2">
            <span>{{ $t('auth.logInPayment.logInWithGoogle') }}</span>
          </v-btn>
          <!-- Login with Google -->

          <!-- Or -->
          <p class="line-through">
            {{ $t('auth.global.or') }}
          </p>
          <!-- /Or -->

          <validation-observer v-slot="{ invalid }">
            <v-form @submit.native.prevent="login()">
              <validation-provider
                v-slot="{ errors }"
                name="email"
                rules="required|email"
              >
                <v-text-field
                  v-model="forms.login.email"
                  :label="$t('auth.global.email')"
                  outlined
                  required
                  :disabled="loaders.login || loaders.socialLogin"
                  background-color="white"
                  :error-messages="errors"
                  :placeholder="$t('auth.global.enterEmail')"
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="password"
                rules="required"
              >
                <v-text-field
                  v-model="forms.login.password"
                  :label="$t('auth.global.password')"
                  outlined
                  required
                  :disabled="loaders.login || loaders.socialLogin"
                  background-color="white"
                  :type="showPassword ? 'text' : 'password'"
                  :placeholder="$t('auth.global.enterPassword')"
                  :error-messages="errors"
                >
                  <template #append>
                    <span @click="showPassword = !showPassword">
                      <v-icon>
                        {{ showPassword ? icons.mdiEye : icons.mdiEyeOff }}
                      </v-icon>
                    </span>
                  </template>
                </v-text-field>
              </validation-provider>

              <v-btn
                block
                x-large
                type="submit"
                color="primary"
                elevation="0"
                :disabled="loaders.login || loaders.socialLogin || invalid"
                :loading="loaders.login"
                @click="login()"
              >
                <span class="font-outfit-semi-bold button-continue">{{ $t('continue') }}</span>
              </v-btn>
            </v-form>
          </validation-observer>

          <!-- Terms & Conditions -->
          <p>{{ $t('auth.global.byContinuing') }} <a
            href="https://ideabuddy.com/terms-of-service/" target="_blank"
          >{{ $t('auth.global.termsAndConditions') }}</a> {{ $t('auth.global.and') }}
            <a href="https://ideabuddy.com/privacy-policy/" target="_blank">{{ $t('auth.global.privacyPolicy') }}</a>.
          </p>
          <!-- /Terms & Conditions -->

          <!-- Go To Login -->
          <p class="already-have">
            <span>{{ $t('auth.logInPayment.dontHave') }}</span>
            &nbsp;
            <a @click="goToSignUp">{{ $t('auth.logInPayment.signUp') }}</a>
          </p>
          <!-- /Go To Login -->
        </div>
      </div>
    </authentication-service-provider></v-app>
</template>

<script>
import { mdiEye, mdiEyeOff } from '@mdi/js'
import AuthenticationServiceProvider from '@/components/ServiceProviders/AuthenticationServiceProvider.vue'
import LocaleChanger from '@/components/LocaleChanger.vue'
import MixinUser from '@/mixins/user'

export default {
  name: 'Login',

  components: { AuthenticationServiceProvider, LocaleChanger },

  mixins: [MixinUser],

  data () {
    return {
      showPassword: false,
      icons: { mdiEye, mdiEyeOff }
    }
  },

  methods: {
    onLoginSuccess (user) {
      this.$refs.authenticationServiceProvider.redirectAfterLogin(user)
    },

    async onLoginSocialSuccess () {
      if (this.isTemplateFromSite) {
        await this.$router.push({ name: 'select-idea', query: this.$router.currentRoute.query })
      } else {
        await this.$router.push({ name: 'home' })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  background: #F5F6FA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #292f4d;

  .form-wrapper {
    width: 100%;
    max-width: 500px;

    h1 {
      max-width: 450px;
      margin: 20px auto 40px;
      font-size: 26px;

      @include media-breakpoint-up($sm) {
        font-size: 32px;
      }
    }

    p {
      font-size: 14px;
    }

    .line-through {
      overflow: hidden;
      text-align: center;

      &:before,
      &:after {
        background-color: rgba(41, 47, 77, 0.09);
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 50%;
      }

      &:before {
        right: 1rem;
        margin-left: -50%
      }

      &:after {
        left: 1rem;
        margin-right: -50%;
      }
    }

    span {
      text-transform: none;
    }

    img {
      width: 19px;
    }

    .button-continue {
      text-transform: uppercase;
    }

    .button-google {
      background: white;

      span {
        color: #292f4d;
      }
    }

    .already-have {
      margin-top: 60px;
    }
  }
}
</style>
