<template>
  <div class="card-wrapper d-flex flex-column position-relative h-100" :class="{ 'popular': popular, 'dark': dark}">
    <popular-tag v-if="popular" />
    <h2 class="plan-title font-outfit-semi-bold">
      {{ title }}
    </h2>
    <p class="plan-description flex-auto mt-0">
      {{ description }}
    </p>
    <div class="description">
      <!-- -->
      <plan-card-price
        :period="period"
        :price="price"
        :monthly-price="monthlyPrice"
        :currency="currencySymbol"
        :loading="loading"
        :dark="dark"
      />
      <p class="price-no-ideas">
        {{ $t('pages.pricing.numberOf') }}
        <ib-tooltip :content="$t('pages.pricing.numberOfIdeas')" :open-delay="0" :dark="!dark">
          <span class="text-lowercase">{{ $tc('idea', 2) }}</span>
        </ib-tooltip> &
        <ib-tooltip :content="$t('pages.pricing.numberOfCollaborators')" :open-delay="0" :dark="!dark">
          <span class="text-lowercase">{{ $tc('collaborator', 2) }}</span>
        </ib-tooltip>
      </p>
    </div>
    <!--    <div v-if="!price" class="flex-auto my-4">-->
    <!--      <img src="@/assets/img/pricing/plans/stars.svg" alt="stars">-->
    <!--    </div>-->

    <div class="actions">
      <!-- Price switcher -->
      <input-number
        v-model="content"
        class="input-number"
        :options="options"
        :disabled="loading"
      />

      <!-- CTA button -->
      <ib-button
        class="cta-button w-100"
        :variant="dark ? 'yellow' : 'blue'"
        size="sm"
        :disabled="!price || loading"
        :loading="buttonLoading"
        @click="onChoosePlan"
      >
        {{ $t('buyNow') }}
      </ib-button>
      <!--      <ib-button v-else class="cta-button w-100" size="sm" @click="onContact">-->
      <!--        {{ $t('pages.pricing.contactUs') }}-->
      <!--      </ib-button>-->
    </div>

    <div class="features text-left">
      <h5 class="title font-outfit-medium">
        {{ $t('pages.pricing.featuresIncluded') }}
      </h5>
      <ul>
        <plan-card-ai-requests :num-of-requests="plan ? plan.aiRequests : null" :dark="dark" />
        <li
          v-for="feature in features"
          :key="feature.id"
          :class="{ 'active': !disabledFeatures || !disabledFeatures.includes(feature.id) }"
        >
          <ib-tooltip :content="feature.hint" :open-delay="0" :disabled="disabledFeatures && disabledFeatures.includes(feature.id)" :dark="!dark">
            <span>{{ feature.text }}</span>
          </ib-tooltip>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import InputNumber from '@/views/Payment/Components/Sections/Plans/InputNumber'
import PlanCardAiRequests from '@/views/Payment/Components/Sections/Plans/PlanCardAiRequests.vue'
import PlanCardPrice from '@/views/Payment/Components/Sections/Plans/PlanCardPrice'
import PopularTag from '@/views/Payment/Components/Sections/Plans/PopularTag'

export default {
  name: 'PlanCard',

  components: {
    PlanCardAiRequests,
    PlanCardPrice,
    InputNumber,
    PopularTag
  },

  props: {
    value: {
      type: [Number, String, Object],
      default: 1
    },
    plan: {
      validator: value => typeof value === 'object' || value === null,
      required: true
    },
    dark: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    popular: {
      type: Boolean,
      default: false
    },
    price: {
      type: [Number, String],
      default: null
    },
    monthlyPrice: {
      validator: value => typeof value === 'string' || value === null,
      default: null
    },
    currencySymbol: {
      type: String,
      default: '$'
    },
    period: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    buttonLoading: {
      type: Boolean,
      default: false
    },
    disabledFeatures: {
      type: Array,
      default: null
    }
  },

  data () {
    return {
      content: this.value
    }
  },

  computed: {
    features () {
      return [
        {
          id: 1,
          text: this.$t('pages.pricing.featuresName.templates'),
          hint: this.$t('pages.pricing.featuresTexts.templates')
        }, {
          id: 2,
          text: this.$t('pages.pricing.featuresName.ideaPlan'),
          hint: this.$t('pages.pricing.featuresTexts.ideaPlan')
        }, {
          id: 3,
          text: this.$t('pages.pricing.featuresName.businessGuide'),
          hint: this.$t('pages.pricing.featuresTexts.businessGuide')
        }, {
          id: 4,
          text: this.$t('pages.pricing.featuresName.financialPlan'),
          hint: this.$t('pages.pricing.featuresTexts.financialPlan')
        }, {
          id: 5,
          text: this.$t('pages.pricing.featuresName.pAndCashFlow'),
          hint: this.$t('pages.pricing.featuresTexts.pAndCashFlow')
        }, {
          id: 6,
          text: this.$t('pages.pricing.featuresName.export'),
          hint: this.$t('pages.pricing.featuresTexts.export')
        }, {
          id: 7,
          text: this.$t('pages.pricing.featuresName.shareIdeas'),
          hint: this.$t('pages.pricing.featuresTexts.shareIdeas')
        }, {
          id: 8,
          text: this.$t('pages.pricing.featuresName.inviteCollaborators'),
          hint: this.$t('pages.pricing.featuresTexts.inviteCollaborators')
        }, {
          id: 9,
          text: this.$t('pages.pricing.featuresName.aiTips'),
          hint: this.$t('pages.pricing.featuresTexts.aiTipsTooltip')
        }, {
          id: 10,
          text: this.$t('pages.pricing.featuresName.ideaValidation'),
          hint: this.$t('pages.pricing.featuresTexts.ideaValidation')
        }, {
          id: 11,
          text: this.$t('pages.pricing.featuresName.businessPlan'),
          hint: this.$t('pages.pricing.featuresTexts.businessPlan')
        }, {
          id: 12,
          text: this.$t('pages.pricing.featuresName.whiteboard'),
          hint: this.$t('pages.pricing.featuresTexts.whiteboard')
        }
      ]
    }
  },

  watch: {
    content: {
      handler () {
        this.$emit('input', this.content)
      },
      immediate: true
    },
    value: {
      handler (value) {
        this.content = value
      },
      immediate: true
    }
  },

  methods: {
    onChoosePlan () {
      this.$emit('choose-plan', this.value)
    },

    onContact () {
      this.$emit('contact')
    },

    onChange (value) {
      this.$emit('change', value)
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped lang="scss">
$tmp-color-blue: #0e64e6;

.card-wrapper {
  background-color: $color-white;
  border: 3px solid #bbc9e4;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 30px 15px;

  @include media-breakpoint-between($md, $lg) {
    padding-bottom: 24px;
  }

  &.popular {
    background-color: rgba(138, 177, 255, 0.15);
  }

  &.dark {
    background-color: #081e4a;

    .plan-title,
    .plan-description {
      color: $color-white;
    }

    .description {
      margin: 10px 0;

      .price-no-ideas {
        color: $color-white;

        span {
          color: #00b7ff;
        }
      }
    }

    .features {
      .title {
        color: $color-white;
      }

      ul {
        li {
          color: #b2c2e0;

          &.active {
            color: $color-white;
          }
        }
      }
    }
  }

  .plan-title {
    margin: 0 0 10px;
  }

  .plan-description {
    font-size: 14px;

    @include media-breakpoint-up($md) {
      font-size: 13px;
    }

    @include media-breakpoint-up($lg) {
      font-size: 14px;
    }
  }

  .flex-auto {
    @include media-breakpoint-up($md) {
      flex: 1 1 auto;
    }
  }

  .description {
    font-size: 14px;

    @include media-breakpoint-up($md) {
      font-size: 13px;
    }

    @include media-breakpoint-up($lg) {
      font-size: 14px;
    }

    .price-no-ideas span {
      text-decoration: underline;
      text-decoration-style: dashed;
      text-underline-offset: 5px;
      color: $tmp-color-blue;
      cursor: pointer;
    }
  }

  .actions {
    padding: 0 10px;

    .input-number {
      margin-bottom: 10px;
    }

    .cta-button {
      height: 42px;

      ::v-deep {
        span {
          font-size: 16px;
        }
      }
    }
  }

  .features {
    padding-left: 10px;

    .title {
      font-size: 16px;
      margin: 20px 0 21px;
    }

    ul {
      list-style: none;
      padding-left: 25px;
      margin: 0;

      li {
        position: relative;
        font-size: 14px;
        text-decoration: underline;
        text-decoration-style: dashed;
        text-underline-offset: 5px;
        color: #bbc9e4;

        @include media-breakpoint-up($md) {
          font-size: 13px;
        }

        @include media-breakpoint-up($lg) {
          font-size: 14px;
        }

        &:not(:last-child) {
          margin-bottom: 12px;
        }

        &::before {
          content: '';
          position: absolute;
          right: 100%;
          width: 16px;
          height: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          margin-right: 9px;
          background-image: url('~@/assets/img/pricing/plans/circle-xmark.svg');
          cursor: auto;
        }

        &.active {
          cursor: pointer;
          color: $color-black;

          &::before {
            background-image: url('~@/assets/img/pricing/plans/circle-check.svg');
          }
        }
      }
    }
  }
}
</style>
